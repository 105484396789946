<template>
  <div class="layoutBorder">
    <div class="layoutHeader">
          <img svg-inline src="@/assets/vector/GloriaJeans.svg/">
    </div>
    <div class="layoutBody">
      <div>
        <h2>ваш персональный промокод</h2>
        <h1>GJPERF4SB8</h1>
      </div>

      <span>СКИДКА 25% НА ПЕРВЫЙ И ПОВТОРНЫЙ ЗАКАЗЫ,<br> ТОЛЬКО ТОВАРЫ С ПОЛНОЙ ЦЕНОЙ НА 25% ДО 31.08</span>

    </div>

    <div class="layoutFooter">
      <button v-on:click="toShop">За покупками!</button>
    </div>


  </div>
</template>

<script>
export default {
  name: "promoPageGJ",
  methods: {
    toShop() {
      window.open( 'https://www.gloria-jeans.ru/', '_blank' )
    }
  },
}
</script>

<style scoped>
img { width: 58.82vw }
.layoutBody { text-align: center }
span { font-weight: bold }
.layoutFooter { display: flex; justify-content: center }
h2 { font-weight: normal; font-size: 4.88vw }
h1 { margin-bottom: 13vw }
span { font-size: 2.94vw; line-height: normal }

@media screen and (min-width: 760px) {
  img { width: 27.5vw }
  .layoutHeader { display: flex; justify-content: center }
  h2 { font-size: 2.42vw }
  h1 { margin-bottom: 2.42vw }
  span { font-size: 1.11vw }
}
@media screen and ( min-width: 1240px ) {
  img { width: 400px }
  h2 { font-size: 32px }
  h1 { margin-bottom: 52px }
  span { font-size: 14px }

}
</style>